import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { MILAN_PHONE, Link } from '../../../utils'
// import { FaPhone } from 'react-icons/fa'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons';

export const StoreCard = ({ thumbnails, link, store, ...rest }) => {
  return (
    <div {...rest} className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3">
      <div className="card mb-4 border-0 rounded-0 shadow-sm">
        <div className="store-thumbnail">
          {
            thumbnails[store.pathname] ?
              <img src={thumbnails[store.pathname]} className="mx-auto w-100" alt="" />
              : <StaticImage src="../../../images/locations/coming-soon-cam-sm.webp" className="h-100" alt="Coming soon" />
          }
        </div>
        <div className="card-body">
          <h5 className="card-title text-center text-capitalize milan-text-primary">
            {store.marketingCity}
          </h5>
          <div className="card-text text-center milan-text-navy">
            <p>
              {store.address}<br />
              {store.addressCity === '' ? store.marketingCity : store.addressCity}, {store.stateAbbrev} {store.zipCode}
            </p>

            <div className="mb-2">
              <a href={`tel:${store.phone === '' ? MILAN_PHONE : store.phone}`} className="btn mb-3 border-0 rounded-0 p-0 d-block">
                <span className="d-block d-sm-none milan-btn milan-primary-btn w-100"><FontAwesomeIcon icon={faPhoneFlip} className="me-2" /> Call Clinic</span>
                <span className="milan-text-navy d-none d-sm-block"><FontAwesomeIcon icon={faPhoneFlip} className="me-2" /> {store.phone === '' ? MILAN_PHONE : store.phone}</span>
              </a>
              <Link
                to={link}
                className="milan-btn milan-primary-btn w-100">Visit Clinic Page</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}