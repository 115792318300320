import React from 'react'
import { useThumbnails } from '../../../hooks'
import { MILAN_SITE_START } from '../../../utils'
import { StoreCard } from '.'

export function OurMetroStores({ currentMetro }) {
  const thumbnails = useThumbnails(currentMetro)

  return (
    <section id="our-metro-stores" className="pb-5 light-gray-bg">
      <div className="container">
        <div className="row justify-content-center">
          {
            currentMetro.stores.map((store, x) => (
              <StoreCard
                key={x} store={store} thumbnails={thumbnails}
                link={`/locations/${currentMetro.metroPath}/${store.pathname}/`}
              />
            ))
          }
          <span id="borrowed-stores"></span>
        </div>
        {
          currentMetro.borrowed_stores && currentMetro.borrowed_stores.length !== 0 &&
          <>
            <h2 className="mt-5 pb-4 text-center milan-text-primary subheadsm">Nearby Locations</h2>
            <div className="row justify-content-center">
              {
                currentMetro.borrowed_stores.map((store, x) => (
                  <StoreCard
                    key={x} store={store.details} thumbnails={thumbnails}
                    link={`${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.details.pathname}/`}
                  />
                ))
              }
            </div>
          </>
        }
      </div>
    </section>
  )
}

export default OurMetroStores