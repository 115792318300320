import React from 'react'
import { ToWords } from 'to-words'

export function MetroHero({ currentMetro }) {
  const toWords = new ToWords()
  const openStoresInThisSite = currentMetro.stores.filter(store => store.open) // returns Array
  const notOpenStoresInThisSite = currentMetro.stores.filter(store => !store.open) // returns Array

  return (
    <section className="w-100 hero light-gray-bg pt-0">
      <div className="container-fluid background"></div>
      <div id="metro-description" className="container-lg px-0">
        <div className="row g-0 justify-content-center">
          <div className="col-lg-10 col-xxl-9">
            <div className="card rounded-0 border-0">
              <div className="card-body">
                <h1 className="card-title text-center pt-4 milan-text-primary pb-0">Our {currentMetro.name} Locations</h1>
                <p className="card-text p-4 lh-lg milan-text-navy">
                  { // at least one store is open
                    openStoresInThisSite.length !== 0 &&
                    <>
                      We have {toWords.convert(openStoresInThisSite.length).toLowerCase()} convenient {currentMetro.name} area locations{notOpenStoresInThisSite.length === 0 ? '.' : <span>, with {toWords.convert(notOpenStoresInThisSite.length).toLowerCase()} more location{notOpenStoresInThisSite.length === 1 ? '' : 's'} opening soon.</span>}
                      {' We are currently open '}
                      {
                        openStoresInThisSite.map((store, x) => (
                          <span key={x}>
                            {store.description.line_one ? store.description.line_one : `at ${store.address}`} in {store.marketingCity}{x === openStoresInThisSite.length - 2 ? '; and ' : x === openStoresInThisSite.length - 1 ? '. ' : '; '}
                          </span>
                        ))
                      }
                    </>
                  }
                  { // at least one store is NOT open
                    notOpenStoresInThisSite.length !== 0 &&
                    <>
                      {`Our new location${notOpenStoresInThisSite.length > 1 ? 's are' : ' is'} coming soon to `}
                      {
                        notOpenStoresInThisSite.map((store, x) => (
                          <span key={x}>{store.marketingCity}{x === notOpenStoresInThisSite.length - 2 ? ', and ' : x === notOpenStoresInThisSite.length - 1 ? '. ' : ', '}</span>
                        ))
                      }
                    </>
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MetroHero